<template>
    <div class="contBox">
        <v-container class="fill-height">
            <v-row>
                <div class="ma-auto">
                    <v-card class="px-13 pt-6 pb-12" outlined>
                        <!-- <v-card-title class="text-center">VueTube</v-card-title> -->
                        <!-- <v-card-subtitle class="mb-5">Sign in</v-card-subtitle> -->
                        <div class="text-center py-4"><img src="@/assets/login/logo.png" class="headLogin" /></div>
                        <v-card-text>
                            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                <form @submit.prevent="handleSubmit(signin)" @reset.prevent="reset">
                                    <ValidationProvider v-slot="{ errors }" name="账号" rules="required|account">
                                        <v-text-field v-model="email" rounded :error-messages="errors" label="电话号码/邮箱地址" placeholder="电话号码/邮箱地址" solo>
                                        </v-text-field>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" name="密码" rules="required">
                                        <v-text-field v-model="password" rounded type="password" :error-messages="errors" label="密码" solo>
                                        </v-text-field>
                                    </ValidationProvider>
                                    <ValidationProvider v-slot="{ errors }" name="验证码" rules="required">
                                        <div class="d-flex">
                                            <v-text-field v-model="code" appen="aaa" rounded type="text" :error-messages="errors" label="验证码" solo>
                                                <template v-slot:append>
                                                    <v-fade-transition leave-absolute>
                                                        <img class="verifyImg" appen="aaa" :src="verifyImg" alt="验证码" @click="updateVerify" />
                                                    </v-fade-transition>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </ValidationProvider>
                                    <div class="mb-4 d-flex justify-space-between">
                                        <v-btn text small class="pl-0 text-capitalize" router to="reset">
                                            <v-icon size="18">mdi-help-circle-outline</v-icon>
                                            忘记密码
                                        </v-btn>
                                        <v-btn text small class="pl-0 text-capitalize" router to="signup">
                                            注册
                                        </v-btn>
                                    </div>
                                    <v-btn type="submit" class="submit-btn" block rounded depressed>登录</v-btn>
                                </form>
                            </ValidationObserver>
                        </v-card-text>
                        <div style="width:340px; margin:auto;display:flex;justify-content: space-evenly;" class="mb-4">
                            <img style="width:35px;cursor: pointer;" @click="toLogin(3)" src="@/assets/login/qq.png" />
                            <img style="width:35px;cursor: pointer;" @click="toLogin(2)" src="@/assets/login/wb.png" />
                            <img style="width:35px;cursor: pointer;" @click="toLogin(1)" src="@/assets/login/wechat.png" />
                            <img style="width:35px;cursor: pointer;" @click="toLogin(4)" src="@/assets/login/dy.png" />
                        </div>
                        <span class="text-button">登录即表示同意</span>
                        <v-btn text small class="px-0 text-capitalize" color="primary" @click="dialog=true;model=0">用户协议</v-btn>
                        、
                        <v-btn text small class="px-0 text-capitalize" color="primary" @click="dialog=true;model=2">隐私条款</v-btn>
                    </v-card>
                </div>
            </v-row>
        </v-container>
        <v-dialog v-model="dialog" width="500">
            <v-card elevation="2" width="600" height="550">
                <v-tabs v-model="model" centered>
                    <v-tab v-for="(item,i) in ['用户协议','社区公约','隐私']" :key="i" :href="`#tab-${i}`">
                        {{item}}
                    </v-tab>
                </v-tabs>
                <v-tabs-items class="taCont" v-model="model">
                    <v-tab-item v-for="(item,index) in agree" :key="index" :value="`tab-${index}`">
                        <v-card flat>
                            <v-card-text v-html="item"></v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="isShow"  :timeout="timeout">
            {{showToast}}
        </v-snackbar>
    </div>
</template>
<script>
export default {
    name: "SignIn",
    data: () => ({
        email: "",
        password: "",
        code: "",
        verifyImg: "",
        string: "",
        isShow: false,
        showToast: '',
        timeout: 1500,
        agree: [],
        model: 0,
        dialog: '',
    }),
    mounted() {
        this.string = this.randomString(10);
        this.verifyImg =
            this.vuex_baseUrl + "/api/Checkcode/getVerify/font_size/18/imageW/150/imageH/58.html?string=" + this.string + "&v=" + Math.ceil(Math.random() * 10);
        let mandate = this.GetRequest();
        if (mandate.token) {
            this.vuex('vuex_token', mandate.token);
            this.showToast = '登录成功';
            this.isShow = true;
            setTimeout(() => {
                this.$router.push('/')
            }, 1500);
        } else if (mandate.openid && mandate.type) {
            this.showToast = '请先绑定账号';
            this.isShow = true;
            setTimeout(() => {
                this.$router.push('/binding?openid=' + mandate.openid + '&type=' + mandate.type)
            }, 500);
        }
        this.getAgree();
    },
    updated() {
    },
    methods: {
        
        GetRequest() {
            const url = window.location.href.split('?')[1]; //获取url中"?"符后的字串
            let theRequest = new Object();
            if (url) {
                let strs = url.split("&");
                for (let i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                }
            }
            return theRequest;
        },
        // 跳转第三方登录 
        toLogin(type) {
            if (type == 1) {
                location.href = this.vuex_baseUrl + "/addons/synclogin/login/type/weixin.html";
            } else if (type == 2) {
                location.href = this.vuex_baseUrl + "/addons/synclogin/login/type/sina.html";
            } else if (type == 3) {
                location.href = this.vuex_baseUrl + "/addons/synclogin/login/type/qq.html";
            } else if (type == 4) {
                location.href = this.vuex_baseUrl + "/addons/synclogin/login/type/douyin.html";
            }
        },
        updateVerify() {
            this.verifyImg = this.vuex_baseUrl + "/api/Checkcode/getVerify/font_size/18/imageW/150/imageH/58.html?string=" + this.string +
                "&v=" + Math.ceil(Math.random() * 10);
        },
        signin() {
            this.$ajax.post("/api/Login/login", {
                username: this.email,
                password: this.password,
                equipment: "PC",
                verify: this.code,
                string: this.string,
            }).then((res) => {
                if (res.code == 1) {
                    this.vuex('vuex_token', res.token)
                    this.vuex('vuex_user', res.data);
                    // console.log('vuex_tokenvuex_token', this.vuex_token)
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 1500);
                } else {
                    this.updateVerify();
                }
                this.showToast = res.msg;
                this.isShow = true;
            });
        },
        // 获取协议
        getAgree() {
            this.$ajax.post('/api/login/agreement').then(res => {
                if (res.code == 1) {
                    this.agree[0] = res.agreement;
                    this.agree[1] = res.convention;
                    this.agree[2] = res.privacy;
                    this.$forceUpdate;
                }
            })
        },
        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789",
                a = t.length,
                n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n;
        },
    },
};
</script>

<style lang="scss" scoped>
.contBox {
    height: 100%;
    width: calc(100% - 40px);
    max-width: 100%;
    margin: 0 20px;
    background: url('../../assets/login/login_bg.png') no-repeat;
    background-size: 100% auto;
    .headLogin {
        width: 120px;
    }
    & ::v-deep .v-input__control .v-input__slot {
        box-shadow: none !important;
        background: #efefef;
        width: 383px;
        height: 56px;
        margin-bottom: 4px;
    }
    .submit-btn {
        background: #d10d2b;
        color: #fff;
        width: 383px;
        height: 56px;
        background: #d10d2b;
        border-radius: 28px;
    }
    & ::v-deep .v-input__append-inner {
        margin: 0;
    }
    & ::v-deep .v-label {
        font-size: 15px;
    }
}
.taCont {
    height: 500px;
    overflow-y: scroll;
}
.taCont::-webkit-scrollbar {
    width: 0px;
    background: #ededed;
}
.taCont::-webkit-scrollbar-thumb {
    width: 5px;
    background: #929292;
    border-radius: 5px;
    cursor: pointer;
}
</style>
